
import * as styles from './content-headings.module.scss';
import PropTypes from 'prop-types';
import React from 'react';

const TertiaryHeading = ({children, className}) => (
    <h4 className={styles.pageTertiaryHeading + (className ? ` ${className}` : "")}>{ children }</h4>
);

TertiaryHeading.propTypes = {
    className: PropTypes.string
};

export default TertiaryHeading;