
import * as styles from './landing-page-price.module.scss';
import React from 'react';
import TertiaryHeading from "../contentHeadings/TertiaryHeading";

const LandingPagePrice = props => {
    const { services } = props;

    return(
        <div className={styles.wrapper}>
            <TertiaryHeading className={styles.heading}>{ props.heading || "Priser" }</TertiaryHeading>
            {
                services.map(service => (
                    <React.Fragment key={service.name}>
                        <p className={styles.serviceName}>{ service.name }</p>
                        <p className={styles.price}>{ service.price }</p>
                    </React.Fragment>
                ))
            }
        </div>
    )
};

export default LandingPagePrice;