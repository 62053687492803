import * as grid from "../styles/grid.module.scss";
import * as styles from '../styles/priser.module.scss';
import Button from '../components/button/Button';
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";
import LandingPagePrice from "../components/landingPages/landingPagePrice";
import ContentMargins from "../components/layout/ContentMargins";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';


const vlServices = [
    {name: "Vippeløft", price: "650,-"},
    {name: "Vippeløft med farge", price: "890,-"},
    {name: "Vippeløft med farge for to personer", price: "1390,-"}
];

const vxServices = [
    {name: "Nytt sett klassisk", price: "1590,-"},
    {name: "Nytt sett naturlig volum (2D-3D)", price: "1590,-"},
    {name: "Nytt sett dramatisk volum (4D+)", price: "1790,-"},
    {name: "Nytt sett brune vipper", price: "1790,-"},
    {name: "Påfyll 90 minutter", price: "990,-"},
    {name: "Påfyll 60 minutter", price: "790,-"}
];

const Priser = () => {
    const { desktop, medium, small, extrasmall } = useStaticQuery(
        graphql`
      query {
        desktop: file(relativePath: { eq: "background/grunerlokka_background_16-7.jpg" }) {
          childImageSharp {
            fluid(quality: 30, maxWidth: 4160, srcSetBreakpoints: [4160, 3500, 3000, 2500, 2000, 1920, 1500]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        medium: file(relativePath: { eq: "background/grunerlokka_background_3-2.jpg" }) {
          childImageSharp {
            fluid(quality: 30, maxWidth: 1400, srcSetBreakpoints: [1400, 1200, 1000]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        small: file(relativePath: { eq: "background/grunerlokka_background_1-1.jpg" }) {
          childImageSharp {
            fluid(quality: 30, maxWidth: 900, srcSetBreakpoints: [900, 700, 500]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        extrasmall: file(relativePath: { eq: "background/grunerlokka_background_vertical.jpg" }) {
          childImageSharp {
            fluid(quality: 30, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
    );

    // Art-Direction Array
    const backgroundArtDirectionStack = [
        extrasmall.childImageSharp.fluid,
        {
            ...small.childImageSharp.fluid,
            media: `(min-width: 601px)`
        },
        {
            ...medium.childImageSharp.fluid,
            media: `(min-width: 901px)`,
        },
        {
            ...desktop.childImageSharp.fluid,
            media: `(min-width: 1401px)`,
        },
    ];

    return(
        <Layout>
            <Seo title={'Priser - Vippeextensions og vippeløft'} />
            <ContentMargins className={styles.priceTitle}>
                <PageTitle>Priser - Vippeextensions og vippeløft</PageTitle>
            </ContentMargins>
            <BackgroundImage
                Tag={"div"}
                className={styles.backgroundImage}
                fluid={backgroundArtDirectionStack}
                title={"Bakgrunnsbilde av salongen"}
                role={"img"}
                aria-label={"Bakgrunnsbilde"}
                preserveStackingContext
            >
                <ContentMargins>
                    <div>
                        <div className={grid.container}>
                            <div className={grid.xs12Sm6P8 + " " + styles.priceWindow}>
                                <LandingPagePrice services={vxServices} heading={"Vippeextensions"}/>
                            </div>
                            <div className={grid.xs12Sm6P8 + " " + styles.priceWindow}>
                                <LandingPagePrice services={vlServices} heading={"Vippeløft"}/>
                            </div>
                        </div>
                        <div className={styles.buttonWrapper}>
                            <Button to={"/booking"}>Se ledige timer</Button>
                        </div>
                    </div>
                </ContentMargins>
            </BackgroundImage>
        </Layout>
    )
};

export default Priser;